import React, { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { mixUrl, formatTime } from 'multiPublic'

import './index.scss'
import prevIcon from '../image/swiper-icon-prev.png'
import nextIcon from '../image/swiper-icon-next.png'
import adTitleIcon from '../image/ad-carousel-title.png'

export default () => {
    const { primaryImgData, recommendImgData } = useSelector((state) => ({
        primaryImgData: state.home.primaryImgData.length > 6 ? state.home.primaryImgData.slice(0, 6) : state.home.primaryImgData,
        recommendImgData: state.home.recommendImgData
    }), shallowEqual)

    // 将二维数组扁平化
    const flattenedRecommendData = recommendImgData ? recommendImgData.flat() : []

    const bigImgData = primaryImgData?.length > 1 ? primaryImgData[0] : null
    return (
        <>
            <div className="left-ad-carousel">
                <a href={bigImgData.url} target="_blank" className="primary-news-link">
                    <div className="image-container" style={{ backgroundImage: `url(${bigImgData.pcImgSrc})` }}>
                    </div>
                    <div className="news-title"><span>{bigImgData.title}</span></div>
                </a>
                <div className="secondary-news-container">
                    {primaryImgData?.slice(0, 6)?.map((item, index) => {
                        if (index === 0) return null
                        return <div
                                 key={item.id}
                                 className="secondary-news-link">
                            <a href={item.url}
                                 target="_blank">
                                <span>{item.title}</span>
                            </a>
                        </div>
                    })}
                </div>
            {/* <Swiper className="left-ad-carousel-primary"
                preventClicks={false}
                loop
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                navigation={{ nextEl: '.swiper-button-next.rm-next', prevEl: '.swiper-button-prev.rm-prev' }}
                pagination={{ el: '.swiper-pagination.rm-pag', type: 'progressbar' }}>
                {
                    primaryImgData && primaryImgData.map((item, index) => {
                        return (
                            <SwiperSlide className="comment-news" key={index}>
                                <a title={item.title} target="_blank" href={item.url}>
                                    <div className="img-div">
                                        <img className="news-img" alt={item.title} src={item.pcImgSrc} />
                                    </div>
                                    <span className="mode"></span>
                                    <div className="title">{item.title}</div>
                                    {item.useType === 1 ? <div className="ad-title">
                                        <img src={adTitleIcon} />
                                    </div> : null}
                                </a>
                            </SwiperSlide>
                        )
                    })
                }
                <div className="perv-bg">
                    <div className="swiper-button-prev rm-prev">
                        <img className="btn-img" src={prevIcon} />
                    </div>
                </div>
                <div className="next-bg">
                    <div className="swiper-button-next rm-next">
                        <img className="btn-img" src={nextIcon} />
                    </div>
                </div>
                <div className="swiper-pagination rm-pag"></div>
            </Swiper> */}
        </div>
        <div className="home-today-recommend">
            <div className="recommend-header">
                <h2>精选推荐</h2>
                {/* <div className="date">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.66641 6.33435H14.3331V13.3344C14.3331 13.7025 14.0346 14.001 13.6664 14.001H2.33308C1.96489 14.001 1.66641 13.7025 1.66641 13.3344V6.33435Z" fill="#333333" stroke="#333333" stroke-width="1.33333" stroke-linejoin="round"/>
                        <path d="M1.66641 3.00102C1.66641 2.63283 1.96489 2.33435 2.33308 2.33435H13.6664C14.0346 2.33435 14.3331 2.63283 14.3331 3.00102V6.33435H1.66641V3.00102Z" stroke="#333333" stroke-width="1.33333" stroke-linejoin="round"/>
                        <path d="M5.33359 1.33435V4.00102" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.6664 1.33435V4.00102" stroke="#333333" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.33359 11.3344H11.3336" stroke="#F2F5FA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.66641 11.3344H6.66641" stroke="#F2F5FA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.33359 8.66565H11.3336" stroke="#F2F5FA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.66641 8.66565H6.66641" stroke="#F2F5FA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>{formatTime(new Date(), 'MM月dd日') }</span>
                </div> */}
            </div>
            <div className="recommend-list">
                {flattenedRecommendData?.slice(0, 4)?.map((item, index) => (
                    <div className="recommend-item" key={index}>
                        <div className="item-link">
                            <span className="tag"/>
                            <a href={item.url} target="_blank" className="title">{item.title}</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </>
    )
}
